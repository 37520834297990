/**
 * IssueReporter module.
 * @module IssueReporter
 *
 * For users reporting issues with the website. Included only on the dev or staging environment.
 */

import {apoe2023Globals} from "@scripts/apoe2023_globals.js";

/**
 * Class representing an issue reporter.
 */
class IssueReporter {
  /**
   * Create an issue reporter instance.
   * @param {string} formId - The ID of the Google Form.
   * @param {string} urlFieldId - The ID of the URL field in the Google Form.
   */
  constructor(formId, urlFieldId) {
    this.formId = formId;
    this.urlFieldId = urlFieldId;
    this.init();
  }

  /**
   * Initialize the issue reporter.
   */
  init() {
    const container = document.createElement('div');
    container.classList.add(
      'fixed',
      'bottom-4',
      'left-4',
      'm-4',
      'apoe2023-report-container',
      'transition-all',
      'duration-300',
      'ease-in-out',
      'transform',
    );

    const button = document.createElement('button');
    button.innerHTML = `
    <span class="default-text">Report</span>
    <span class="hover-text">Problem with This Page</span>
  `;
    button.classList.add(
      'bg-base-link',
      'hover:bg-orange',
      'text-white',
      'text-xs',
      'font-bold',
      'py-1',
      'px-4',
      'rounded-full',
      'opacity-50',
      'hover:opacity-100',
      'hover:scale-110',
      'transition-all',
      'hover:transition-all',
      'duration-300',
      'ease-in-out',
      'transform',
      'apoe2023-report-button'
    );

    container.appendChild(button);
    container.addEventListener('click', this.openForm.bind(this));
    document.body.appendChild(container);

    // Add dynamic styles
    const styles = `
    .apoe2023-report-container {
      padding: 10px;
      cursor: pointer;
    }
    .apoe2023-report-button {
      width: 80px;
      height: 30px;
      overflow: hidden;
      transition: width 0.3s ease-in-out;
    }
    .apoe2023-report-container:hover .apoe2023-report-button {
      width: 250px;
    }
    .apoe2023-report-button .hover-text {
      display: none;
    }
    .apoe2023-report-container:hover .apoe2023-report-button .hover-text {
      display: inline;
    }
  `;

    const styleElement = document.createElement('style');
    styleElement.innerHTML = styles;
    document.head.appendChild(styleElement);
  }

  /**
   * Open the Google Form to report an issue.
   */
  openForm() {
    const pageUrl = window.location.href;
    const formUrl = `https://docs.google.com/forms/d/e/${this.formId}/viewform?usp=pp_url&${this.urlFieldId}=${encodeURIComponent(pageUrl)}`;
    window.open(formUrl, '_blank');
  }
}

// Initialize the issue reporter when the DOM is ready
apoe2023Globals.domReady(() => {
  new IssueReporter('1FAIpQLSdub7rzQaOtyy8wW3V5rtyweYXB1jb17KAnnH7DwvAeDzIJFw', 'entry.1633084358');
});
