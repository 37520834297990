export const apoe2023Globals = {
  /**
   * Object containing screen sizes for responsive design.
   * @type {Object}
   */
  screens: {
    xs: 420,
    sm: 640,
    md: 781,
    lg: 1024,
    xl: 1024,
    xxl: 1200,
    '2xl': 2048,
  },

  /**
   * Debounce Function: Delays the processing of the function until after
   * time has elapsed since the last time this function was invoked.
   *
   * @param {Function} func - The function to debounce.
   * @param {Number} delay - The delay in milliseconds.
   * @returns {Function} - Debounced function.
   */
  debounce: (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  },

  /**
   * Executes a function when the DOM is ready.
   * If the DOM is still loading, it adds an event listener for the 'DOMContentLoaded' event to execute the function.
   * If the DOM is already loaded, it executes the function immediately.
   *
   * @param {Function} func - The function to execute when the DOM is ready.
   * @param {...*} args - The arguments to pass to the function.
   */
  domReady: (func, ...args) => {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => func(...args));
    } else {
      func(...args);
    }
  },

  /**
   * Executes a function when all assets are loaded.
   * If the assets are still loading, it adds an event listener for the 'load' event to execute the function.
   * If the assets are already loaded, it executes the function immediately.
   *
   * @param {Function} func - The function to execute when all assets are loaded.
   * @param {...*} args - The arguments to pass to the function.
   */
  assetsLoaded: (func, ...args) => {
    if (document.readyState !== 'complete') {
      window.addEventListener('load', () => func(...args));
    } else {
      func(...args);
    }
  },
}
